import React from "react";
import CollagePage from "./collage-page";
import { WhatWouldYouDo } from "./what-would-you-do";
import { SeaOfMemories } from "./sea-of-memories";

export const Main10: React.FC = () => {
  return (
    <CollagePage
      backgroundImageUri="/images/main/5.jpeg"
      soundUri="/sounds/sound_05.mp3"
      linkToPrevPage="/9"
      linkToThisPage="/10"
      linkToNextPage="/11"
      captionLeft="mainCaptions.balticMemories"
      captionRight="mainCaptions.wwyd"
      imageLeftUri="/images/main/collage-09-min.png"
      imageRightUri="/images/main/collage-10-min.png"
      leftModalContent={(onClose) => <SeaOfMemories onClose={onClose} />}
      rightModalContent={(onClose) => <WhatWouldYouDo onClose={onClose} />}
    />
  );
};

