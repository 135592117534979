import React from "react";
import { Box, Container, Link, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Heading1 } from "../components/typography/Heading1";
import { BodyText } from "../components/typography/BodyText";
import { JnsLink } from "../components/typography/JnsLink";

export const Credits: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      minHeight="calc(100vh - 40px)"
      backgroundImage="/images/background-paper.jpg"
      backgroundSize="contain"
      paddingTop="112px"
      paddingBottom="3rem"
      position="relative">
      <Container
        display="flex"
        height="100%"
        maxW="72ch"
        pb="1rem"
        flexDir="column"
        justifyContent="space-between">
        <VStack alignItems="flex-start" gap="24px">
          <Heading1>{t("credits.title")}</Heading1>
          <BodyText>{t("credits.texts")}</BodyText>
          <BodyText>{t("credits.translations")}</BodyText>
          <BodyText>{t("credits.collectionAndArchivePictures")}</BodyText>
          <BodyText>{t("credits.seaAndLandscapePhotos")}</BodyText>
          <BodyText>{t("credits.underwaterPhotos")}</BodyText>
          <BodyText>{t("credits.fishingPhotos")}</BodyText>
          <BodyText>{t("credits.satellitePhoto")}</BodyText>
          <BodyText>{t("credits.algaePhoto")}</BodyText>
          <BodyText>{t("credits.tractorPhoto")}</BodyText>
          <BodyText>{t("credits.harbourAndTankerPhoto")}</BodyText>
          <BodyText>{t("credits.foodAndDogPhoto")}</BodyText>
          <BodyText>{t("credits.supPhoto")}</BodyText>
          <BodyText>{t("credits.sounds")}</BodyText>
          <Box>
            <BodyText>{t("credits.conceptCurationAndImplementation")}
              <JnsLink to="https://atcontent.fi/" target="_blank">All Things Content</JnsLink>
              {t("credits.and")}
              <JnsLink to="https://www.indevit.fi/" target="_blank">Indevit</JnsLink>
            </BodyText>
          </Box>
        </VStack>
      </Container>
    </Box >)
};

