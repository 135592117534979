import { Box, Text } from "@chakra-ui/layout";
import StickyArea from "../sticky-area";
import { useRef } from "react";
import { Container } from "@chakra-ui/react";
import { ArticleHeader } from "./article-header";
import { ArticleFooter } from "./article-footer";
import { ArticleAreaSectionView } from "./article-area-section-view";

interface ArticleContainerProps {
  onClose: () => void;
  title: string;
  footerTitle?: string;
  headerParagraphs: string[];
  areas: ArticleArea[];
  footerParagraphs: string[]
}

export interface ArticleArea {
  useScrollAnimation: boolean;
  items: Array<ArticleAreaSection>
  background?: string;
}

export interface ArticleConfig {
  articleId: string;
  title: string;
  headerParagraphs: string[];
  footerParagraphs: string[]
  areas: ArticleArea[];
}

export interface ArticleAreaSection {
  background?: string;
  title?: string;
  text?: string;
  image?: string
  imageAfter?: string;
}

const ArticleContainer: React.FC<ArticleContainerProps> = ({
  onClose,
  title,
  headerParagraphs,
  areas,
  footerParagraphs,
  footerTitle
}) => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const markerDiv = useRef<HTMLDivElement | null>(null);

  const handleClickScrollDown = () => {
    markerDiv.current?.scrollIntoView({ behavior: "smooth", "block": "start" })
  }

  return (
    <Box
      height="100vh"
      overflowY="auto"
      position="relative"
      background="darkBlue"
      ref={scrollContainer}
    >
      <ArticleHeader
        onClickScrollDown={handleClickScrollDown}
        onClickBack={onClose}
        title={title}
        paragraphs={headerParagraphs} />
      <Box ref={markerDiv}></Box>
      {areas.map((x, i) => {
        return (
          <StickyArea
            key={i}
            sections={x.items}
            scrollContainerRef={scrollContainer}
          >
            <Container maxWidth="92ch" position="relative">
              {x.items.map((x, i) => <ArticleAreaSectionView key={i} {...x} />)}
            </Container>
          </StickyArea>
        );
      })}
      <ArticleFooter
        title={footerTitle}
        paragraphs={footerParagraphs}
        onClickBack={onClose}
        socialTitle={title}
      />
    </Box>
  );
};

export default ArticleContainer;
