import { QuizHeader } from "../components/quizzes/quiz-header"
import { TabArticle } from "../components/tab-article/tab-article"
import { TabArticleTabContent } from "../components/tab-article/tab-article-tab-content"

interface Props {
  onClose: () => void
}

export const Hope: React.FC<Props> = ({ onClose }) => {

  const conservation = <TabArticleTabContent
    title="hope.conservation.title"
    intro="hope.conservation.body"
    image="/images/hope/YT5_Toivo-04-ver2.jpg"
    paragraphs={[]}
    credits="hope.conservation.photo"
  />

  const recovery = <TabArticleTabContent
    title="hope.recovery.title"
    intro="hope.recovery.body"
    image="/images/hope/YT5_Toivo-05.jpg"
    paragraphs={[]}
    credits="hope.recovery.photo"
  />

  const returning = <TabArticleTabContent
    title="hope.return.title"
    intro="hope.return.body"
    image="/images/hope/YT5_Toivo-06.jpg"
    paragraphs={[]}
    credits="hope.return.photo"
  />

  return (
    <TabArticle
      onClose={onClose}
      baseRoute="/8/right/"
      socialTitle=""
      header={<QuizHeader
        onClickBack={onClose}
        title="hope.title"
        paragraphs={[
          "hope.intro"
        ]}></QuizHeader>}
      tabs={[
        {
          title: "hope.conservation.title",
          image: "/images/hope/YT5_Toivo-01.png",
          content: conservation
        },
        {
          title: "hope.recovery.title",
          image: "/images/hope/YT5_Toivo-02.png",
          content: recovery
        },
        {
          title: "hope.return.title",
          image: "/images/hope/YT5_Toivo-03.png",
          content: returning
        }
      ]}
      quote={{
        quote: "hope.quote",
        attribution: "hope.attribution"
      }}
    />
  )
}