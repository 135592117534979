import { useState } from "react"

export const useRippedPaper = () => {

  const rippedPapers = [{
    src: "/images/rippedpaper-01.png",
    magicNumber: 8.4,
  }, {
    src: "/images/rippedpaper-02.png",
    magicNumber: 10,
  },
  {
    src: "/images/rippedpaper-03.png",
    magicNumber: 9.7,
  },
  {
    src: "/images/rippedpaper-04.png",
    magicNumber: 8.4,
  }
  ]

  const getRandomPaper = () => {
    let randomIndex = Math.round(Math.random() * rippedPapers.length - 1);
    
    if (randomIndex < 0)
      randomIndex = 0;

    return rippedPapers[randomIndex]
  }

  const [randomPaper] = useState(getRandomPaper());

  return randomPaper;
}