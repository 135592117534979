import { BoxProps, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { RippedPaper } from "./ripped-paper";
import { JnsLink } from "./typography/JnsLink";

interface Link {
  text: string;
  to: string;
}

interface TextBoxProps extends BoxProps {
  text: string;
  link?: Link;
}

const TextBox: React.FC<TextBoxProps> = (props: TextBoxProps) => {
  const { text, link, ...other } = props;

  return (
    <RippedPaper {...other}>
      <VStack 
        paddingX="24px"
        gap="4px"
        paddingTop="16px"
        paddingBottom="8px">
        <Text
          whiteSpace="pre-line"
          fontSize="20px"
          lineHeight="24px"
          color="brand"
          fontFamily="Lora"
          fontWeight="400"
        >
          {text.replace(/\.\s/g, ".\n")}
        </Text>
        {link && (
          <JnsLink to={link.to}>
            {link.text}
          </JnsLink>
        )}
      </VStack>
    </RippedPaper>
  );
};

export default TextBox;
