import { Box } from "@chakra-ui/react"

export interface TwitterProps {
  title: string;
  urlToShare: string;
  color: string;
}

export const Twitter: React.FC<TwitterProps> = ({ color, title, urlToShare }) => {

  const shareToTwitter = () => {
    const text = title;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(urlToShare)}&text=${encodeURIComponent(text)}`;
    window.open(twitterUrl, '_blank', 'noopener,noreferrer');
};

  return (
    <Box as="button" onClick={shareToTwitter}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_1399_2839)">
          <mask id="mask0_1399_2839" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
            <path d="M19.8068 0H0.191406V20H19.8068V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1399_2839)">
            <path d="M11.8652 8.46429L19.1675 0H17.4371L11.0965 7.34942L6.03235 0H0.191406L7.84945 11.1136L0.191406 19.9897H1.92191L8.6177 12.2285L13.9659 19.9897H19.8068L11.8648 8.46429H11.8652ZM9.49506 11.2115L8.71915 10.1049L2.54543 1.29901H5.20338L10.1856 8.40562L10.9616 9.51229L17.4379 18.7498H14.78L9.49506 11.212V11.2115Z" fill={color} />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1399_2839">
            <rect width="19.6154" height="20" fill="white" transform="translate(0.191406)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}