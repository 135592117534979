import React from "react";
import Quiz from "../components/quizzes/quiz";
import { QuizHeader } from "../components/quizzes/quiz-header";
import QuizSection from "../components/quizzes/quiz-section";

interface Props {
  onClose: () => void;
}

export const ThreateningBalticQuiz: React.FC<Props> = ({ onClose }) => {

  const questions = [
    {
      "question": "whatIsThreateningBaltic.eutrophication.question",
      "answers": [
        "whatIsThreateningBaltic.eutrophication.a",
        "whatIsThreateningBaltic.eutrophication.b",
        "whatIsThreateningBaltic.eutrophication.c"],
      "correctAnswer": "whatIsThreateningBaltic.eutrophication.c",
      "fact": "whatIsThreateningBaltic.eutrophication.fact",
      "explanations": [
        "whatIsThreateningBaltic.eutrophication.explanations.eutrophyingNutrients",
        "whatIsThreateningBaltic.eutrophication.explanations.wasteWaterTreatment",
        {
          imageUri: "/images/environmentalWork/YT3_Uhka-04.jpg"
        },
        "whatIsThreateningBaltic.eutrophication.explanations.forestDrainage",
        "whatIsThreateningBaltic.eutrophication.explanations.altoughHalved"
      ],
      "imageUri": "/images/environmentalWork/YT3_Uhka-01.png",
    },
    {
      "question": "whatIsThreateningBaltic.climateChange.question",
      "answers": [
        "whatIsThreateningBaltic.climateChange.a",
        "whatIsThreateningBaltic.climateChange.b",
        "whatIsThreateningBaltic.climateChange.c"],
      "correctAnswer": "whatIsThreateningBaltic.climateChange.c",
      "fact": "whatIsThreateningBaltic.climateChange.fact",
      "explanations": [
        "whatIsThreateningBaltic.climateChange.explanations.warmingSeaWater",
        "whatIsThreateningBaltic.climateChange.explanations.rainFall",
        {
          imageUri: "/images/environmentalWork/YT3_Uhka-05.jpg"
        },
        "whatIsThreateningBaltic.climateChange.explanations.slowingItDown"
      ],
      "imageUri": "/images/environmentalWork/YT3_Uhka-02.png",
    },
    {
      "question": "whatIsThreateningBaltic.chemicals.question",
      "answers": [
        "whatIsThreateningBaltic.chemicals.a",
        "whatIsThreateningBaltic.chemicals.b",
        "whatIsThreateningBaltic.chemicals.c"],
      "correctAnswer": "whatIsThreateningBaltic.chemicals.b",
      "fact": "whatIsThreateningBaltic.chemicals.fact",
      "explanations": [
        "whatIsThreateningBaltic.chemicals.explanations.environmentalToxins",
        {
          imageUri: "/images/environmentalWork/YT3_Uhka-06.jpg"
        },
        "whatIsThreateningBaltic.chemicals.explanations.tonnesOfOil",
        "whatIsThreateningBaltic.chemicals.explanations.harmfulSubstancesEntering",
      ],
      "imageUri": "/images/environmentalWork/YT3_Uhka-03.png",
    },
  ]

  return (
    <Quiz
      onClose={onClose}
      header={<QuizHeader
        onClickBack={onClose}
        title="whatIsThreateningBaltic.whatIsThreateningBaltic"
        paragraphs={["whatIsThreateningBaltic.threats", "whatIsThreateningBaltic.whichAreTrue"]} />}
      socialTitle="whatIsThreateningBaltic.whatIsThreateningBaltic"
      sections={questions.map((x, i) =>
        <QuizSection key={i} {...x} />
      )}
    />
  );
};

