import { Box, BoxProps } from "@chakra-ui/react";
import { MutableRefObject, ReactNode } from "react";

interface StickyContainerProps extends BoxProps {
  stickyElementRef: MutableRefObject<HTMLDivElement | null>;
  children?: ReactNode;
}

const StickyContainer: React.FC<StickyContainerProps> = ({
  stickyElementRef,
  children,
  ...props
}) => {
  return (
    <>
      <Box
        ref={stickyElementRef}
        {...props}
      >
        {children}
      </Box>
    </>
  );
};

export default StickyContainer;
