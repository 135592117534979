import React from "react";
import ArticleContainer from "../components/articles/article-container";

export interface GoalsCourageProps {
  onClose: () => void;
}

export const GoalsCourage: React.FC<GoalsCourageProps> = ({ onClose }) => {
  const article = {
    articleId: "YT4_rohkeus",
    title: "yt4_rohkeus.goalsCourageOpportunities",
    headerParagraphs: ["yt4_rohkeus.saveTheBalticSea"],
    footerParagraphs: ["yt4_rohkeus.foundationsWorkOnEnvironment", "yt4_rohkeus.photo"],
    areas: [
      {
        background: "/images/YT4_rohkeus/YT4_BG1.jpg",
        useScrollAnimation: true,
        items: [{
          background: "/images/environmentalWork/YT4_BG1.jpg",
          title: "yt4_rohkeus.nothingIsImpossible",
          text: "yt4_rohkeus.describedAsPolluted"
        }, {
          image: "/images/environmentalWork/YT4_Rohkeus-01.png",
          text: "yt4_rohkeus.tacklingTheProblems"
        }, {
          background: "/images/environmentalWork/YT4_BG2.jpg",
          title: "yt4_rohkeus.smallFoundation",
          text: "yt4_rohkeus.foundationStartedIn"
        }, {
          image: "/images/environmentalWork/YT4_Rohkeus-02.01.png",
          text: "yt4_rohkeus.biggestSourceOfPollution"
        }, {
          image: "/images/environmentalWork/YT4_Rohkeus-02.02.png",
          text: "yt4_rohkeus.jointProjectWithStPetersburg"
        }, {
          background: "/images/environmentalWork/YT4_BG3.jpg",
          title: "yt4_rohkeus.fishSoup",
          text: "yt4_rohkeus.fishingEmotions"
        }, {
          image: "/images/environmentalWork/YT4_Rohkeus-03.01.png",
          text: "yt4_rohkeus.fishingRecycleNutrients"
        },
        {
          image: "/images/environmentalWork/YT4_Rohkeus-03.02.png",
          text: "yt4_rohkeus.localFishingProject"
        }],
      }]
  }

  return (
    <ArticleContainer
      onClose={onClose}
      {...article}
    />
  );
};

