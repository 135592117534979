import { Text, TextProps } from "@chakra-ui/react";


export const Heading1: React.FC<TextProps> = (props) => {
  return <Text
    color="brand"
    fontSize="56px"
    fontWeight="500"
    lineHeight="60px"
    fontFamily="Lora"
    {...props}></Text>
}