import { Box, Button, Container, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import QuizAnswer from "./quiz-answer";
import QuizQuestion from "./quiz-question";

export interface QuizSectionProps {
  imageUri: string;
  question: string;
  answers: string[];
  fact: string;
  explanations: QuestionExplanation[];
  correctAnswer: string;
}

export interface QuestionExplanationImage {
  imageUri: string;
}

export type QuestionExplanation = string | QuestionExplanationImage


const QuizSection: React.FC<QuizSectionProps> = (question) => {
  const { imageUri } = question;
  return (
    <Box as="section">
      <Image src={imageUri} marginBottom="1rem" />
      <QuizQuestion question={question.question} />
      <QuizAnswer
        answers={question.answers}
        correctAnswer={question.correctAnswer}
        fact={question.fact}
        explanations={question.explanations}
      />
    </Box>
  );
};

export default QuizSection;
