import React from "react";
import CollagePage from "./collage-page";
import { Hope } from "./hope";
import { Foundation } from "./foundation";
import usePreLoadImage from "../hooks/preload-image";

export const Main8: React.FC = () => {
  usePreLoadImage([
    "/images/main/5.jpeg"
  ]);
  
  return (
    <CollagePage
      backgroundImageUri="/images/main/4.jpeg"
      soundUri="/sounds/sound_04-2.mp3"
      linkToPrevPage="/7"
      linkToThisPage="/8"
      linkToNextPage="/9"
      captionLeft="mainCaptions.foundation"
      captionRight="mainCaptions.positiveMessages"
      imageLeftUri="/images/main/collage-07-min.png"
      imageRightUri="/images/main/collage-08-min.png"
      leftModalContent={(onClose) => <Foundation onClose={onClose} />}
      rightModalContent={(onClose) => <Hope onClose={onClose} />}
    />
  );
};

