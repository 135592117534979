import React from "react";
import Quiz from "../components/quizzes/quiz";
import { QuizHeader } from "../components/quizzes/quiz-header";
import QuizSection from "../components/quizzes/quiz-section";

export interface MyhicalMapsProps {
    onClose: () => void;
}

export const Mythbuster: React.FC<MyhicalMapsProps> = ({onClose}) => {
    const questions = [
        {
          "question": "areYouMythBuster.balticSeaMostPolluted.question",
          "answers": ["generic.true", "generic.false"],
          "correctAnswer": "generic.false",
          "fact": "areYouMythBuster.balticSeaMostPolluted.fact",
          "explanations": ["areYouMythBuster.balticSeaMostPolluted.explanation"],
          "imageUri": "/images/environmentalWork/YT_Myytteja-01.png",
        },
        {
          "question": "areYouMythBuster.individualsWontSaveBaltic.question",
          "answers": ["generic.true", "generic.false"],
          "correctAnswer": "generic.false",
          "fact": "areYouMythBuster.individualsWontSaveBaltic.fact",
          "explanations": ["areYouMythBuster.individualsWontSaveBaltic.explanation"],
          "imageUri": "/images/environmentalWork/YT_Myytteja-02.png",
        },
        {
          "question": "areYouMythBuster.finlandHelpless.question",
          "answers": ["generic.true", "generic.false"],
          "correctAnswer": "generic.false",
          "fact": "areYouMythBuster.finlandHelpless.fact",
          "explanations": ["areYouMythBuster.finlandHelpless.explanation"],
          "imageUri": "/images/environmentalWork/YT_Myytteja-03.png",
        }];
    
    return (
        <Quiz
            onClose={onClose}
            header={<QuizHeader
                onClickBack={onClose}
                title="areYouMythBuster.areYouMythBuster"
                paragraphs={["areYouMythBuster.manyMyths", "areYouMythBuster.trueOrFalse"]}/>}
            socialTitle="areYouMythBuster.areYouMythBuster"
            sections={questions.map((x,i)=> 
                <QuizSection key={i} {...x}/>
            )}
        />
  );
};

