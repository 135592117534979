import { Box } from "@chakra-ui/react"

export const FadeToBlackOnExit: React.FC = () => {
  return (
    <Box
      className="black-box"
      pos="absolute"
      top={0}
      right={0}
      bottom={0}
      left={0}
      bg="#000000"
      opacity="0"
      pointerEvents="none"
      zIndex={999999}
    >
    </Box>
  )
}