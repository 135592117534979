import { Button, ButtonProps } from "@chakra-ui/react";

interface Props extends ButtonProps {
  primary?: boolean;
}

export const NavButton = (props: Props) => {
  const { primary, ...chakra } = props;
  const color = primary ? "#191D43" : "white";
  const background = primary ? "white" : "rgba(255, 255, 255, 40%)"

  return (
    <Button
      border="1px solid white"
      background={background}
      color={color}
      padding="24px 16px"
      minW="50px"
      fontFamily="DM Sans"
      borderRadius="32px"
      {...chakra}>
    </Button>
  )
}