import React from "react";
import BasicPage from "./basic-page";
import usePreLoadImage from "../hooks/preload-image";


export const Main5: React.FC = () => {
  usePreLoadImage([
    "/images/main/collage-05-min.png",
    "/images/main/collage-06-min.png"
  ]);
  return (
    <BasicPage
      soundUri="/sounds/sound_03.mp3"
      backgroundImageUri="/images/main/3.jpeg"
      linkToPrevPage="/4"
      linkToNextPage="/6"
    />
  );
};

