import React from "react";
import BasicPage from "./basic-page";
import usePreLoadImage from "../hooks/preload-image";


export const Main3: React.FC = () => {
  usePreLoadImage([
    "/images/main/collage-03-min.png",
    "/images/main/collage-04-min.png"
  ]);
  return (
   <BasicPage 
    backgroundImageUri="/images/main/2.jpeg" 
    soundUri="/sounds/sound_02.mp3"
    linkToPrevPage="/2"
    linkToNextPage="/4"
    />
  );
};

