import { Box, Text } from "@chakra-ui/layout";
import { Button, Container, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";
import { BackButton } from "../buttons/back-button";
import { NavButtonContainer } from "../buttons/nav-button-container";

interface ArticleHeaderProps {
  title?: string;
  paragraphs: string[];
  onClickScrollDown: () => void;
  onClickBack: () => void;
  titleComponent?: React.ReactNode;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  title,
  paragraphs,
  onClickScrollDown,
  onClickBack,
  titleComponent
}) => {

  const { t } = useTranslation();

  return (
    <Box as="header"
      minHeight="100vh"
      backgroundImage="/images/common/background-darkblue.jpg"
      position="relative"
      paddingBottom="3rem"
      backgroundSize="cover">
      <Container
        height="100%"
        pt="25vh"
        maxW="85ch"
        pb="3rem">
        <BackButton onClick={onClickBack} buttonColor="white" />
        {title && <Text color="white" fontSize="xlarge" lineHeight="1" marginTop="1.5rem" fontFamily="Lora">{t(title)}</Text>}
        {titleComponent}
        {paragraphs.map(x => <Text key={x} color="white" marginTop="1.5rem" dangerouslySetInnerHTML={{ __html: t(x) }} />)}
      </Container>
      <NavButtonContainer pos="absolute">
        <Button
          variant="unstyled"
          color="#A6F9FF"
          _hover={{
            color: "#639599"
          }}
          display="flex"
          onClick={onClickScrollDown}
          flexDir="column">
          {t("generic.scrollDown")}
          <Icon fontSize="1.5rem"><FiChevronDown /></Icon>
        </Button>
      </NavButtonContainer>
    </Box>
  )
};
