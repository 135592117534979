import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect
} from "react";
import { useLocation } from "react-router-dom";

interface VisitedLinkContextProps {
  isVisited: (path: string) => boolean;
}

const VisitedLinkContext = createContext<VisitedLinkContextProps>({
  isVisited: () => false
});

export const useVisitedLinks = () => useContext(VisitedLinkContext);

export const VisitedLinkProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {

  const [visitedLinks, setVisitedLinks] = useState<string[]>([]);
  const location = useLocation();
  useEffect(() => {
    setVisitedLinks(() => visitedLinks.concat(location.pathname));
  }, [location])

  const isVisited = (pathName: string) => visitedLinks.includes(pathName);

  return (
    <VisitedLinkContext.Provider value={{ isVisited: isVisited }}>
      {children}
    </VisitedLinkContext.Provider>
  );
};
