import { Box } from "@chakra-ui/react"

export interface FacebookProps {
  title: string;
  urlToShare: string;
  color: string;
}

export const Facebook: React.FC<FacebookProps> = ({ color, title, urlToShare }) => {

  const share = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}&t=${title}`;
    window.open(facebookUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box as="button" onClick={share}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M18.1914 2H15.1914C13.8653 2 12.5936 2.52678 11.6559 3.46447C10.7182 4.40215 10.1914 5.67392 10.1914 7V10H7.19141V14H10.1914V22H14.1914V14H17.1914L18.1914 10H14.1914V7C14.1914 6.73478 14.2968 6.48043 14.4843 6.29289C14.6718 6.10536 14.9262 6 15.1914 6H18.1914V2Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Box>
  )
}