import { Box } from "@chakra-ui/react";
import { MutableRefObject, useEffect, useState } from "react";

interface StickyBackgroundProps {
  stickyElementRef: MutableRefObject<HTMLDivElement | null>;
  imageIndex: number;
  images: (string|undefined)[];
}

const StickyBackground: React.FC<StickyBackgroundProps> = ({
  images,
  imageIndex,
  stickyElementRef,
}) => {
  // The complexity in this file comes because a normal css
  // transition between different background images looks wonky
  // when they have different aspect ratio, so instead we crossfade
  // two divs.

  // State to control the opacity of the background images
  const [currentImageOpacity, setCurrentImageOpacity] = useState(1);
  const [prevImageOpacity, setPrevImageOpacity] = useState(1);

  // State to store the previous image index
  const [prevImageIndex, setPrevImageIndex] = useState(imageIndex);

  useEffect(() => {
    // When the image index changes, start the crossfade transition
    if (prevImageIndex !== imageIndex) {
      // Fade out the previous image
      setPrevImageOpacity(0);
      // After the fade-out transition (500ms), set up the next image
      const id = setTimeout(() => {
        setPrevImageIndex(imageIndex); // Update the previous image index
        setCurrentImageOpacity(1);     // Fade in the new image
        setPrevImageOpacity(1);        // Reset the opacity for the next transition
      }, 500); // This duration should match the CSS transition time
    }
  }, [imageIndex, prevImageIndex]);

  return (
    <Box
      ref={stickyElementRef}
      position="relative"
      h="100vh"
      mb="-100vh"
      width="100%"
      top="0"
    >
      {images.map((img, idx) => (
        <Box
          key={idx}
          position="absolute"
          width="100%"
          height="100%"
          backgroundImage={`url(${img})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          opacity={idx === prevImageIndex ? prevImageOpacity : idx === imageIndex ? currentImageOpacity : 0}
          transition="opacity 0.5s ease"
        />
      ))}
    </Box>
  );
};

export default StickyBackground;
