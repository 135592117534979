import { Image, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizAnswerButton } from "./quiz-answer-button";
import { QuestionExplanation } from "./quiz-section";
import { LabelPrimaryEmphasis } from "../typography/LabelPrimaryEmphasis";
import { BodyText } from "../typography/BodyText";

interface QuizAnswerProps {
  answers: string[];
  correctAnswer: string;
  fact: string;
  explanations: QuestionExplanation[];
}

const QuizAnswer: React.FC<QuizAnswerProps> = ({
  answers,
  correctAnswer,
  fact,
  explanations,
}) => {

  const [attempt, setAttempt] = useState<string | null>(null);

  const { t } = useTranslation();

  const isCorrect = (answer: string) => {
    return !!attempt && answer === attempt && attempt === correctAnswer;
  }

  const isWrong = (answer: string) => {
    return !!attempt && answer === attempt && attempt !== correctAnswer;
  }

  return (
    <>
      {answers.map((x, index) => {
        return (
          <QuizAnswerButton
            key={x}
            answer={x}
            isCorrect={isCorrect(x)}
            isWrong={isWrong(x)}
            onClick={() => setAttempt(x)} />
        );
      })}
      <Stack hidden={attempt === null} marginTop="1.5rem" gap="1.5rem">
        <LabelPrimaryEmphasis>
          {t(fact)}
        </LabelPrimaryEmphasis>
        {explanations.map(explanation =>
          typeof (explanation) === "string"
            ? <BodyText key={explanation}>
                {t(explanation)}
              </BodyText>
            : <Image key={explanation.imageUri} src={explanation.imageUri}></Image>
        )}
      </Stack>
    </>
  );
};

export default QuizAnswer;
