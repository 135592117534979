import { Box } from "@chakra-ui/layout";
import { useRef } from "react";
import { Container, Flex, VStack } from "@chakra-ui/react";
import StickyArea from "../components/sticky-area";
import { ArticleFooter } from "../components/articles/article-footer";
import { ArticleHeader } from "../components/articles/article-header";
import { useTranslation } from "react-i18next";
import { ArticleAreaSectionView } from "../components/articles/article-area-section-view";
import { ArticleAreaSectionQuote } from "../components/articles/article-area-section-qoute";
import { RippedPaper } from "../components/ripped-paper";
import { Heading3 } from "../components/typography/Heading3";
import { BodyText } from "../components/typography/BodyText";
import { LabelPrimaryEmphasis } from "../components/typography/LabelPrimaryEmphasis";
import { CheckTag } from "../components/buttons/check-tag";

interface Props {
  onClose: () => void;
}

export const SeaOfMemories: React.FC<Props> = ({
  onClose
}) => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const markerDiv = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const handleClickScrollDown = () => {
    markerDiv.current?.scrollIntoView({ behavior: "smooth", "block": "start" })
  }

  const articleSections = [
    {
      background: "/images/seaOfMemories/06MT_Kiitos-02-Background.jpg",
      text: "seaOfMemories.project"
    },
    {
      quote: "seaOfMemories.quote1.quote",
      attribution: "seaOfMemories.quote1.attribution",
    },
    {
      quote: "seaOfMemories.quote2.quote",
      attribution: "seaOfMemories.quote2.attribution",
    }, {
      quote: "seaOfMemories.quote3.quote",
      attribution: "seaOfMemories.quote3.attribution",
    }, {
      background: "/images/seaOfMemories/06MT_Kiitos-03-Background.jpg",
      quote: "seaOfMemories.quote4.quote",
      attribution: "seaOfMemories.quote4.attribution",
    }, {
      quote: "seaOfMemories.quote5.quote",
      attribution: "seaOfMemories.quote5.attribution",
    }, {
      quote: "seaOfMemories.quote6.quote",
      attribution: "seaOfMemories.quote6.attribution",
    },
  ]

  const noOfTags = 26;
  const tags = [];
  for (let idx = 1; idx <= noOfTags; idx++) {
    tags.push("seaOfMemories.tags." + idx);
  }

  return (
    <Box
      height="100vh"
      overflowY="auto"
      position="relative"
      background="darkBlue"
      ref={scrollContainer}
    >
      <ArticleHeader
        onClickScrollDown={handleClickScrollDown}
        onClickBack={onClose}
        title="seaOfMemories.title"
        paragraphs={[
          "seaOfMemories.bridgeToWorld",
          "seaOfMemories.gratitude"]} />
      <Box ref={markerDiv}></Box>
      <Box
        backgroundImage={`url(/images/seaOfMemories/06MT_Kiitos-01-Background.jpg)`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat">
        <Container
          minH="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingY="100px">
          <RippedPaper>
            <VStack p="2rem" gap="24px" alignItems="left" >
              <Heading3>{t("seaOfMemories.thanks")}</Heading3>
              <BodyText>{t("seaOfMemories.reflect")}</BodyText>
              <BodyText>{t("seaOfMemories.oftenThereIs")}</BodyText>
              <LabelPrimaryEmphasis>{t("seaOfMemories.choose")}</LabelPrimaryEmphasis>
            </VStack>
          </RippedPaper>
          <Flex mt="100px" justifyContent="center" alignContent="flex-start" flexWrap="wrap" gap="24px">
            {tags.map((x, i) => <CheckTag  key={x} index={i}>{t(x)}</CheckTag>)}
          </Flex >
        </Container>
      </Box>
      <StickyArea
        sections={articleSections}
        scrollContainerRef={scrollContainer}>
        <Container position="relative">
          <VStack gap="66vh" pb="66vh">
            {articleSections.map((x, i) =>
              x.quote
                ? <ArticleAreaSectionQuote key={i} {...x} />
                : <ArticleAreaSectionView key={i} {...x} />
            )}
          </VStack>
        </Container>
      </StickyArea>
      <ArticleFooter
        socialTitle="seaOfMemories.title"
        paragraphs={["seaOfMemories.photo"]}
        onClickBack={onClose}
      />
    </Box>
  );
};

