import React from "react";
import { Link } from "react-router-dom";
import { NavButtonContainer } from "./buttons/nav-button-container";
import { NavLeft } from "./buttons/nav-left";
import { NavRight } from "./buttons/nav-right";

interface NavigationContainerProps {
  linkToPreviousPage?: string;
  linkToNextPage?: string;
}

const NavigationContainer: React.FC<NavigationContainerProps> = ({ linkToPreviousPage, linkToNextPage }) => {
  return (
    <NavButtonContainer>
      {linkToPreviousPage && (
        <Link to={linkToPreviousPage}>
          <NavLeft />
        </Link>
      )}

      {linkToNextPage && (
        <Link to={linkToNextPage}>
          <NavRight />
        </Link>
      )}
    </NavButtonContainer>
  );
};

export default NavigationContainer;
