import { Box, Text } from "@chakra-ui/layout";
import { useRef } from "react";
import { Container, VStack } from "@chakra-ui/react";
import StickyArea from "../components/sticky-area";
import { ArticleFooter } from "../components/articles/article-footer";
import { ArticleHeader } from "../components/articles/article-header";
import { StatementView } from "../components/statement-view";
import { useTranslation } from "react-i18next";
import { Subtitle2 } from "../components/typography/Subtitle2";

interface Props {
  onClose: () => void;
}

interface Statement {
  s: string;
  a: string;
}

export const WhatWouldYouDo: React.FC<Props> = ({
  onClose
}) => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const markerDiv = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const handleClickScrollDown = () => {
    markerDiv.current?.scrollIntoView({ behavior: "smooth", "block": "start" })
  }

  const statements: Statement[] = [
    {
      s: "skipMeat",
      a: "skipMeatThanks"
    },
    {
      s: "butts",
      a: "buttThanks"
    },
    {
      s: "veggie",
      a: "veggieThanks"
    },
    {
      s: "walk",
      a: "walkThanks"
    },
    {
      s: "medicine",
      a: "medicineThanks"
    },
    {
      s: "ecoLabel",
      a: "ecoLabelThanks"
    },
    {
      s: "foodWaste",
      a: "foodWasteThanks"
    },
    {
      s: "insects",
      a: "insectsThanks"
    },
    {
      s: "repair",
      a: "repairThanks"
    },
    {
      s: "pee",
      a: "peeThanks"
    }
  ]


  return (
    <Box
      height="100vh"
      overflowY="auto"
      position="relative"
      background="darkBlue"
      ref={scrollContainer}
    >
      <ArticleHeader
        onClickScrollDown={handleClickScrollDown}
        onClickBack={onClose}
        titleComponent={<>
          <Text fontSize="80px" color="white" fontFamily="Caveat Brush">
            {t("thanks.thanks")}
          </Text>
          <Subtitle2 color="white">
            {t("thanks.from")}
          </Subtitle2>
        </>}
        paragraphs={["thanks.subtitle"]} />
      <Box ref={markerDiv}></Box>
      <StickyArea scrollContainerRef={scrollContainer} sections={[{
        background: "/images/thanks/YT6_BG.jpg",
      }]}>
        <Container
          maxWidth="72ch"
          position="relative"
          display="flex"
          flexDirection="column"
          gap="64px"
          paddingY="60vh">
          {statements.map(x => <StatementView key={x.a} statement={"thanks." + x.s} thanks={"thanks." + x.a}></StatementView>)}
        </Container>
      </StickyArea>
      <ArticleFooter
        socialTitle="thanks.subtitle"
        paragraphs={["thanks.thanks", "thanks.photo"]}
        onClickBack={onClose}
      />
    </Box>
  );
};

