import { Box, Text } from "@chakra-ui/layout";
import { Button, Container, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Heading3 } from "../typography/Heading3";
import { ShareWithYourFriends } from "../social/share-with-your-friends";
import { PrimaryButton } from "../buttons/primary-button";

interface ArticleFooterProps {
  title?: string;
  socialTitle: string;
  paragraphs: string[];
  onClickBack: () => void;
}

export const ArticleFooter: React.FC<ArticleFooterProps> = ({
  paragraphs,
  onClickBack,
  title,
  socialTitle
}) => {

  const { t } = useTranslation();

  return (
    <Box as="header"
      height="100vh"
      backgroundImage="/images/common/background-darkblue.jpg"
      backgroundSize="cover">
      <Container
        display="flex"
        height="100%"
        pt="25vh"
        pb="1rem"
        gap="48px"
        flexDir="column">
        <Flex gap="24px" direction="column">
          {title && <Heading3 color="white">{t(title)}</Heading3>}
          {paragraphs.map(x =>
            <Text key={x} color="white" dangerouslySetInnerHTML={{ __html: t(x) }} />
          )}
        </Flex>
        <PrimaryButton scheme="white" onClick={onClickBack} marginBottom="48px">{t("generic.back")}</PrimaryButton>
        <ShareWithYourFriends color="white" title={socialTitle} />
      </Container>
    </Box>
  )
};
