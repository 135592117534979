import { Text, Button, ButtonProps, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiArrowLeft } from "react-icons/fi";

export interface BackButtonProps extends ButtonProps {
  buttonColor: string
}

export const BackButton: React.FC<BackButtonProps> = ({
  buttonColor,
  ...chakra
}) => {

  const { t } = useTranslation();


  return (
    <Button
      variant="unstyled"
      borderRadius="0.5rem"
      px="16px"
      height="48px"
      flexBasis="48px"
      color={buttonColor}
      display="flex"
      justifyContent="spaceBetween"
      alignItems="center"
      border={`1.5px solid ${buttonColor}`}
      _hover={{
        boxShadow: `inset 0 0 1px 1px ${buttonColor}`
      }}
      {...chakra}
    >
      <Icon fontSize="24px" mr="0.75rem" as={FiArrowLeft}></Icon>
      <Text>{t("generic.back")}</Text>
    </Button>
  )
};
