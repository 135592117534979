import React from "react";
import BasicPage from "./basic-page";
import usePreLoadImage from "../hooks/preload-image";


export const Main1: React.FC = () => {
  usePreLoadImage([
    "/images/main/collage-01-min.png",
    "/images/main/collage-02-min.png"
  ]);

  return (
    <BasicPage
      backgroundImageUri="/images/main/1.jpg"
      soundUri="/sounds/sound_01.mp3"
      linkToPrevPage="/"
      linkToNextPage="/2" />
  );
};

