import { Button, ButtonProps } from "@chakra-ui/react";

export interface BackButtonProps extends ButtonProps {
  buttonColor: string
}

export const SecondaryButton: React.FC<BackButtonProps> = ({
  buttonColor,
  ...chakra
}) => {

  return (
    <Button
      variant="unstyled"
      borderRadius="0.5rem"
      overflow="hidden"
      height="48px"
      py=".25rem"
      px="1rem"
      color={buttonColor}
      border={`1.5px solid ${buttonColor}`}
      _hover={{
        boxShadow: `inset 0 0 1px 1px ${buttonColor}`
      }}
      {...chakra}
    ></Button>
  )
};
