import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from "react";

interface SoundContextProps {
  soundOn: boolean;
  toggleSound: () => void;
  setSound: (path: string) => void;
}

const SoundContext = createContext<SoundContextProps>({
  soundOn: false,
  toggleSound: () => {},
  setSound: (path: string) => {},
});

export const useSoundContext = () => useContext(SoundContext);

export const SoundProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [soundOn, setSoundOn] = useState(false);
  const [soundPath, setSoundPath] = useState("");

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const toggleSound = () => {
    setSoundOn((prev) => !prev);
  };

  const setSound = (path: string) => {
    setSoundPath(path);
  };

  useEffect(() => {
  
    const fadeOut = (audio: HTMLAudioElement) => {
      let fadeAudio = setInterval(() => {
        if (audio.volume > 0.01) {
          // Reduce the volume by a fraction of the current volume
          audio.volume -= audio.volume * 0.05;
        } else {
          // When the volume is very low, just set it to 0 and pause
          audio.volume = 0;
          clearInterval(fadeAudio);
          audio.pause();
        }
      }, 25);
    };
  
    if (soundPath === "") {
      audioRef.current && fadeOut(audioRef.current);
    } else {
      audioRef.current?.pause();
      audioRef.current = new Audio(soundPath);
      audioRef.current.loop = true;
      audioRef.current.volume = 1.0;
  
      if (soundOn) {
        setTimeout(() => {
          audioRef.current?.play();
        }, 150);
      }
    }
  }, [soundOn, soundPath]);

  return (
    <SoundContext.Provider value={{ soundOn, toggleSound, setSound }}>
      {children}
    </SoundContext.Provider>
  );
};
