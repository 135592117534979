import { Box, Text } from "@chakra-ui/layout";
import { useState } from "react";
import { Flex, Icon, Stack, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";
import { RippedPaper } from "./ripped-paper";


interface Props {
  statement: string;
  thanks: string;
}

export const StatementView: React.FC<Props> = ({
  statement,
  thanks
}) => {

  const [isSelected, setIsSelected] = useState(false);
  const { t } = useTranslation();

  const background = isSelected ? "#F3FFEC" : "brand";
  const border = isSelected ? "1px solid #9AFF63" : "none";
  const color = isSelected ? "brand" : "white";

  const checkBg = isSelected ? "brand" : "white";

  return (
    <Stack paddingBottom={isSelected ? "3rem" : 0} >
      <Box
        as="button"
        width="100%"
        padding="1rem"
        borderRadius="0.5rem"
        border={border}
        background={background}
        color={color}
        display="flex"
        justifyContent="flex-start"
        onClick={() => setIsSelected(x => !x)}
      >
        <Box
          pos="relative"
          flex="0 0 1rem"
          marginTop="4px"
          height="1rem"
          background={checkBg}
          marginRight="1rem">
          {isSelected && <Icon pos="absolute" left="0" color="white" marginLeft="auto" fontSize="20px"><FiCheck /></Icon>}
        </Box>
        <Text textAlign="left">{t(statement)}</Text>
      </Box>
      {isSelected &&
        <RippedPaper marginX="1rem">
          <Flex flexDirection="row" padding="1rem" gap="1rem">
            <Image flex="0 0 90px" height="90px" src="/images/thanks/JNS_logo_tausta.png"></Image>
            {t(thanks)}
          </Flex>
        </RippedPaper>}
    </Stack>
  );
};

