import { Box } from "@chakra-ui/react"

export interface WhatsappProps {
  title: string;
  urlToShare: string;
  color: string;
}

export const Whatsapp: React.FC<WhatsappProps> = ({ color, title, urlToShare }) => {

  const shareToWhatsApp = () => {
    const message = title + ": " + urlToShare; // Combine your message and URL
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box as="button" onClick={shareToWhatsApp}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.4444 4.53501C18.4754 2.56377 15.857 1.47771 13.0672 1.47656C7.31895 1.47656 2.64049 6.15464 2.63824 11.9046C2.63744 13.7428 3.11767 15.5368 4.03033 17.1184L2.55078 22.5225L8.07929 21.0723C9.60253 21.9031 11.3176 22.3411 13.063 22.3417H13.0673C13.0669 22.3417 13.0676 22.3417 13.0673 22.3417C18.8149 22.3417 23.4937 17.6631 23.4961 11.913C23.4972 9.12635 22.4134 6.50618 20.4444 4.53501ZM13.0672 20.5804H13.0637C11.5084 20.5798 9.98281 20.1619 8.65194 19.3721L8.33545 19.1843L5.05475 20.0449L5.93046 16.8462L5.72434 16.5182C4.85667 15.1382 4.39842 13.5431 4.39909 11.9052C4.40096 7.12606 8.28952 3.23792 13.0707 3.23792C15.3859 3.23864 17.5623 4.14148 19.1989 5.77991C20.8354 7.41833 21.7362 9.59617 21.7352 11.9123C21.7333 16.6918 17.8449 20.5804 13.0672 20.5804Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.8233 14.0877C17.5627 13.9573 16.2816 13.327 16.0427 13.2399C15.8039 13.153 15.6301 13.1095 15.4564 13.3704C15.2828 13.6312 14.7833 14.2182 14.6313 14.3921C14.4794 14.5659 14.3273 14.5878 14.0668 14.4573C13.8062 14.3268 12.9666 14.0517 11.9713 13.1639C11.1966 12.4729 10.6736 11.6197 10.5216 11.3587C10.3696 11.0979 10.5054 10.9569 10.6359 10.8269C10.7531 10.7101 10.8965 10.5226 11.0268 10.3704C11.157 10.2183 11.2005 10.1095 11.2873 9.93573C11.3742 9.76179 11.3308 9.6096 11.2656 9.47921C11.2005 9.34878 10.6794 8.06617 10.4622 7.54433C10.2506 7.03629 10.0358 7.10512 9.87586 7.09703C9.7241 7.08946 9.55015 7.08789 9.37646 7.08789C9.20277 7.08789 8.9204 7.15313 8.6816 7.41394C8.44276 7.67484 7.76953 8.30531 7.76953 9.5878C7.76953 10.8705 8.70328 12.1095 8.83358 12.2834C8.96384 12.4574 10.6711 15.0893 13.285 16.2181C13.9068 16.4866 14.3921 16.6469 14.7706 16.767C15.3948 16.9653 15.9629 16.9373 16.4119 16.8702C16.9125 16.7954 17.9536 16.2399 18.1707 15.6313C18.3879 15.0225 18.3879 14.5007 18.3227 14.3921C18.2576 14.2834 18.0839 14.2182 17.8233 14.0877Z" fill={color} />
      </svg>
    </Box>
  )
}