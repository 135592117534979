import React, { useContext, useEffect } from "react";
import NavigationContainer from "../components/navigation-container";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
} from "@chakra-ui/react";
import TextBox from "../components/text-box";
import { useTranslation } from "react-i18next";
import { useSoundContext } from "../context/sound-context";
import JNSModal from "../components/jns-modal";
import { FadeToBlackOnExit } from "../components/fade-to-black-on-exit";
import { useVisitedLinks } from "../context/visited-link-context";

interface CollagePageProps {
  backgroundImageUri: string
  soundUri: string;
  imageLeftUri: string;
  captionLeft: string;
  imageRightUri: string;
  captionRight: string;
  linkToThisPage: string;
  linkToNextPage: string;
  linkToPrevPage: string;
  leftModalContent: (onClose: () => void) => React.ReactNode
  rightModalContent: (onClose: () => void) => React.ReactNode
}

const CollagePage: React.FC<CollagePageProps> = ({
  backgroundImageUri,
  soundUri,
  imageLeftUri,
  captionLeft,
  imageRightUri,
  captionRight,
  linkToNextPage,
  linkToPrevPage,
  leftModalContent,
  linkToThisPage,
  rightModalContent }) => {

  const { modal } = useParams();

  const navigate = useNavigate();

  const isLeftOpen = modal === "left";
  const isRightOpen = modal === "right";

  const onClose = () => {
    navigate(linkToThisPage, { replace: true });
  }

  const { setSound } = useSoundContext();

  useEffect(() => {
    if (!isLeftOpen && !isRightOpen)
      setSound(soundUri);
    else
      setSound("");
  }, [isLeftOpen, isRightOpen]);

  const location = useLocation();
  const { isVisited } = useVisitedLinks();
  const isLeftVisited = isVisited(location.pathname + "/left");
  const isRightVisited = isVisited(location.pathname + "/right");

  const visitedFilter = "grayscale(100%) brightness(0.66)";

  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      height="calc(100vh - 40px)"
      backgroundImage={`linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('${backgroundImageUri}')`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      position="relative"
    >
      <FadeToBlackOnExit />
      <Box height="100%" overflowY="auto">
        <Flex
          minHeight="calc(100vh - 40px)"
          alignItems="center"
          justifyContent="center"
          flexDirection={{ base: "column", md: "row" }}
          gap={{ base: "140px", md: "48px" }}
          paddingBottom="8rem"
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            cursor="pointer"
            onClick={() => navigate("left")}
            alignItems="center"
          >
            <Image
              maxH="50vh"
              filter={isLeftVisited ? visitedFilter : "undefined"}
              src={imageLeftUri}
              marginBottom="1rem"
            />
            <TextBox
              width="300px"
              text={t(t(captionLeft))}
              link={{ text: t("readMore"), to: "left" }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            cursor="pointer"
            onClick={() => navigate("right")}
            alignItems="center"
          >
            <Image
              maxH="50vh"
              filter={isRightVisited ? visitedFilter : "undefined"}
              src={imageRightUri}
              marginBottom="1rem"
            />
            <TextBox
              width="300px"
              text={t(captionRight)}
              link={{ text: t("readMore"), to: "right" }}
            />
          </Box>
        </Flex>
        <JNSModal
          isOpen={isLeftOpen}
          onClose={onClose}
        >
          {leftModalContent(onClose)}
        </JNSModal>
        <JNSModal
          isOpen={isRightOpen}
          onClose={onClose}
        >
          {rightModalContent(onClose)}
        </JNSModal>
        <NavigationContainer
          linkToNextPage={linkToNextPage}
          linkToPreviousPage={linkToPrevPage}
        />
      </Box>
    </Box>
  );
};

export default CollagePage;

export interface CollageModalContent<T> extends React.FC<T> {
  onClose: () => void;
}
