import { Route } from "react-router-dom";
import SlideRoutes from "./slide-routes";
import { Main1 } from "../pages/main1";
import { Main2 } from "../pages/main2";
import { Main3 } from "../pages/main3";
import { Main4 } from "../pages/main4";
import { Main5 } from "../pages/main5";
import { Main6 } from "../pages/main6";
import { Main7 } from "../pages/main7";
import { Main8 } from "../pages/main8";
import { Main9 } from "../pages/main9";
import { Main10 } from "../pages/main10";
import { Intro } from "../pages/intro";
import { Main11 } from "../pages/main11";
import { Credits } from "../pages/credits";

interface AppRoutsProps { }

const AppRouts: React.FC<AppRoutsProps> = () => {
  return (
    <SlideRoutes duration={300}>
      <Route index element={<Intro />} path="" />
      <Route element={<Main1 />} path="/1" />
      <Route element={<Main2 />} path="/2/:modal?" />
      <Route element={<Main3 />} path="/3" />
      <Route element={<Main4 />} path="/4/:modal?" />
      <Route element={<Main5 />} path="/5" />
      <Route element={<Main6 />} path="/6/:modal?/:tab?" />
      <Route element={<Main7 />} path="/7" />
      <Route element={<Main8 />} path="/8/:modal?/:tab?" />
      <Route element={<Main9 />} path="/9" />
      <Route element={<Main10 />} path="/10/:modal?/:tab?" />
      <Route element={<Main11 />} path="/11" />
      <Route element={<Credits />} path="/credits" />
    </SlideRoutes>
  );
};

export default AppRouts;
