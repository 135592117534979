import { Box } from "@chakra-ui/layout";
import { Container, Link as ChakraLink, Stack, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ShareWithYourFriends } from "../components/social/share-with-your-friends";
import { QuizHeader } from "../components/quizzes/quiz-header";
import { EmbeddedVideo } from "../components/social/embedded-video";
import { LabelPrimaryEmphasis } from "../components/typography/LabelPrimaryEmphasis";
import { PrimaryButton } from "../components/buttons/primary-button";

interface Props {
  onClose: () => void
}

export const Foundation: React.FC<Props> = ({
  onClose

}) => {

  const { t } = useTranslation();

  return (
    <Box
      height="100vh"
      overflow="auto"
      backgroundImage="/images/background-paper.jpg"
      paddingTop="6.5rem"
      paddingBottom="2rem"
      backgroundSize="contain">
      <Container maxW="85ch">
        <VStack gap="4rem">
          <QuizHeader
            onClickBack={onClose}
            title="foundation.title"
            paragraphs={[
              "foundation.mission",
              "foundation.video",
            ]}></QuizHeader>
          <Box width="100%">
            <EmbeddedVideo></EmbeddedVideo>
            <LabelPrimaryEmphasis>{t("foundation.videoText")}</LabelPrimaryEmphasis>
          </Box>
          <PrimaryButton onClick={onClose} width="100%">{t("generic.back")}</PrimaryButton>
          <ShareWithYourFriends title="foundation.title" />
        </VStack>
      </Container >
    </Box>
  );
};

