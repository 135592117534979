import { useTranslation } from "react-i18next";
import { Image, Stack } from "@chakra-ui/react";
import { ArticleAreaSection } from "./article-container";
import { BodyText } from "../typography/BodyText";
import { Heading3 } from "../typography/Heading3";

export const ArticleAreaSectionView = (props: ArticleAreaSection) => {

  const { t } = useTranslation();

  const { text, image, title, imageAfter } = props;

  const imgComponent = image && (<Image src={image} backgroundSize="cover" />);
  const titleComponent = title && <Heading3 lineHeight="1" fontFamily="Lora">{t(title)}</Heading3>
  const textComponent = text && <BodyText dangerouslySetInnerHTML={{__html: t(text)}} />
  const imgAfterComponent = imageAfter && (<Image src={imageAfter} backgroundSize="cover" />);

  const cardComponent = (titleComponent || textComponent) && (<Stack
    color="brand"
    bg="lightLightGray"
    gap="1.5rem"
    p={{ base: "2rem", lg: "3rem" }}
  >
    {titleComponent}
    {textComponent}
  </Stack>);

  return (
    <Stack paddingY="33vh">
      {imgComponent}
      {cardComponent}
      {imgAfterComponent}
    </Stack>
  )
}