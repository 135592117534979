import { extendTheme } from "@chakra-ui/react";

export const colors =
{
  darkBlue: "#1c1c44",
  white: "#fff",
  backgroundWhite: "#e0e4e6",
  black: "#212347",
  blue: "#00a2ff",
  lightBlue: "#7fd0ff",
  paper: "rgb(225, 230, 231)",
  lightLightGray: "#ECEFF0",
  brand: "#22275E",
  gold: "#94895C"
}
export const defaultTheme = extendTheme({
  colors: colors,
  fontSizes: {
    xsmall: "clamp(0.94rem, calc(0.92rem + 0.11vw), 1.00rem)",
    small: "clamp(1.13rem, calc(08rem + 0.22vw), 1.25rem)",
    xmedium: "clamp(1.35rem, calc(1.28rem + 0.37vw), 1.56rem)",
    medium: "clamp(1.94rem, calc(1.77rem + 0.87vw), 2.44rem)",
    large: "clamp(2.33rem, calc(2.08rem + 1.25vw), 3.05rem)",
    xlarge: "clamp(2.80rem, calc(2.45rem + 1.77vw), 3.82rem);",
  },
  fonts: {
    heading: `'Lora'`,
    body: `'DM Sans', sans-serif`,
  },
});
