import React from "react";
import { Box, Container, Icon, Image, Flex } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Heading1 } from "../components/typography/Heading1";
import { BodyText } from "../components/typography/BodyText";
import { RippedPaper } from "../components/ripped-paper";
import { Heading5 } from "../components/typography/Heading5";
import { JnsLink } from "../components/typography/JnsLink";
import { NavButton } from "../components/buttons/nav-button";
import { NavButtonContainer } from "../components/buttons/nav-button-container";
import { NavLeft } from "../components/buttons/nav-left";


export const Main11: React.FC = () => {
  const { t } = useTranslation();

  const links = [
    { translationsKey: "donations", image: "/images/outro/link01-donate-min.png" },
    { translationsKey: "exhibitions", image: "/images/outro/link02-museum-min.png" },
    { translationsKey: "webshop", image: "/images/outro/link03-shop-min.png" },
    { translationsKey: "foundation", image: "/images/outro/link04-JNS-min.png" }
  ];

  return (
    <Box
      height="calc(100vh - 40px)"
      backgroundImage="/images/common/background-darkblue.jpg"
      position="relative"
      backgroundSize="cover">
      <Box height="100%" overflowY="auto">
        <Container
          display="flex"
          pt="70px"
          maxW="72ch"
          pb="2rem"
          flexDir="column"
          gap="24px">
          <Heading1 color="white">{t("outro.title")}</Heading1>
          <BodyText color="white">{t("outro.subtitle")}</BodyText>
        </Container>
        <Container
          maxW="110ch"
          justifyContent="center"
          display="flex"
          gap={{ base: "140px", xl: "24px" }}
          marginBottom="8rem"
          alignItems={{ base: "center", xl: "flex-start" }}
          flexDir={{ base: "column", xl: "row" }}>
          {links.map(x =>
            <Box width="320px" key={x.image}>
              <Image src={x.image}></Image>
              <RippedPaper>
                <Flex direction="column" gap="8px" p="16px" >
                  <Heading5 textAlign="center" >{t(`outro.${x.translationsKey}.title`)}</Heading5>
                  <BodyText textAlign="center" >{t(`outro.${x.translationsKey}.text`)}</BodyText>
                  <JnsLink textAlign="center" target="_blank" to={t(`outro.${x.translationsKey}.linkUrl`)}>{t(`outro.${x.translationsKey}.linkText`)}</JnsLink>
                </Flex>
              </RippedPaper>
            </Box>
          )}
        </Container>
        <NavButtonContainer>
          <Link to="/10">
            <NavLeft></NavLeft>
          </Link>
          <Link to="/">
            <NavButton>
              {t("mainCaptions.toTheStart")}
            </NavButton>
          </Link>
        </NavButtonContainer>
      </Box>
    </Box >)
};

