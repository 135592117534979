import { Text, TextProps } from "@chakra-ui/react";

export const Quote: React.FC<TextProps> = (props) => {

  const { children, ...rest } = props;

  return <Text
    textAlign="center"
    color="brand"
    fontSize="2rem"
    fontWeight="400"
    lineHeight="36px"
    fontFamily="Lora"
    {...rest}>{children}</Text>
}