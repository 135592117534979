import { Box, Text } from "@chakra-ui/layout";
import { Button, Container, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";
import { BackButton } from "../buttons/back-button";
import { colors } from "../../theme/theme";

interface QuizHeaderProps {
  title: string;
  paragraphs: string[];
  onClickBack: () => void;
}

export const QuizHeader: React.FC<QuizHeaderProps> = ({
  title,
  paragraphs,
  onClickBack
}) => {

  const { t } = useTranslation();

  return (
    <Box>
      <BackButton onClick={onClickBack} buttonColor={colors.brand} />
      <Text fontFamily="Lora" color="brand" fontSize="xlarge" lineHeight="1" marginTop="1.5rem">{t(title)}</Text>
      {
        paragraphs.map(x =>
          <Text color="brand" marginTop="1.5rem" key={x}>
            {t(x)}
          </Text>
        )
      }
    </Box>
  )
};
