import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import StickyBackground from "./sticky-background";
import StickyContainer from "./sticky-container";

interface StickyAreaProps {
  children?: ReactNode;
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>;
  name?: string;
  sections: StickyAreaSection[];
}

interface StickyAreaSection
{
  background?: string;
}

const StickyArea: React.FC<StickyAreaProps> = ({
  scrollContainerRef,
  children,
  sections
}) => {
  const stickyBackgroundRef = useRef<HTMLDivElement | null>(null);
  const stickyContainerRef = useRef<HTMLDivElement | null>(null);

  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const func = () => {
      if (
        !stickyContainerRef.current ||
        !stickyBackgroundRef.current ||
        !scrollContainerRef.current
      ) {
        return;
      }

      const endOfStickyArea =
        stickyContainerRef.current.offsetTop +
        stickyContainerRef.current.clientHeight;

      const heightPerArticleSection = stickyContainerRef.current!.clientHeight / sections.length;

      const currentY = scrollContainerRef.current.scrollTop - (stickyContainerRef.current.offsetTop - stickyBackgroundRef.current.clientHeight);
      const offsetY = 200;

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        if (!section.background)
          continue;

        if (i * heightPerArticleSection <= currentY + offsetY)
        {
          setImageIndex(i);
          break;
        }
      }

      const bottomOfScreen =
        scrollContainerRef.current.scrollTop + window.innerHeight;

      if (endOfStickyArea < bottomOfScreen) {
        const stickyElementHeight = stickyBackgroundRef.current.clientHeight;
        stickyBackgroundRef.current.style.position = "absolute";
        stickyBackgroundRef.current.style.top =
          endOfStickyArea - stickyElementHeight + "px";
      } else {
        stickyBackgroundRef.current.style.position = "sticky";
        stickyBackgroundRef.current.style.top = "0";
      }
    };

    scrollContainerRef.current!.addEventListener("scroll", func);
    return () => {
      if (scrollContainerRef && scrollContainerRef.current)
        scrollContainerRef.current.removeEventListener("scroll", func);
    };
  }, [sections]);

  return (
    <>
      <StickyBackground
        images={sections.map(x => x.background)}
        imageIndex={imageIndex}
        stickyElementRef={stickyBackgroundRef}
      />
      <StickyContainer stickyElementRef={stickyContainerRef}>
        {children}
      </StickyContainer>
    </>
  );
};

export default StickyArea;
