import { useEffect, useState } from "react";

const usePreLoadImage = (imageList: string[]) => {
  const [imagesPreLoaded, setImagesPreLoaded] = useState<boolean>(false);

  const preLoadImage = (src: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;

      img.style.display = "none";
      document.body.appendChild(img);
    });
  };

  useEffect(() => {
    let isCancelled = false;

    const effect = async () => {
      if (isCancelled) return;

      const imagesPromiseList: Promise<any>[] = [];

      for (const i of imageList) {
        imagesPromiseList.push(preLoadImage(i));
      }
      await Promise.all(imagesPromiseList);

      if (isCancelled) return;

      setImagesPreLoaded(true);
    };

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreLoaded };
};

export default usePreLoadImage;
