import React, { useEffect } from "react";
import NavigationContainer from "../components/navigation-container";
import { useLocation } from "react-router-dom";
import PageTransition from "../components/page-transition";
import { useSoundContext } from "../context/sound-context";
import { FadeToBlackOnExit } from "../components/fade-to-black-on-exit";

interface BasicPageProps { 
  backgroundImageUri: string;
  soundUri: string;
  linkToPrevPage?: string;
  linkToNextPage?: string;
}

export const BasicPage: React.FC<BasicPageProps> = ({
  backgroundImageUri, 
  soundUri,
  linkToNextPage,
  linkToPrevPage}) => {
  const location = useLocation();
  
  const { setSound } = useSoundContext();

  useEffect(() => {
    setSound(soundUri);
  }, [location.pathname]);

  return (
    <PageTransition
      backgroundImageUri={backgroundImageUri}
    >
      <FadeToBlackOnExit />
      <NavigationContainer 
        linkToPreviousPage={linkToPrevPage} 
        linkToNextPage={linkToNextPage} />
    </PageTransition>
  );
};

export default BasicPage;
