import { Link, LinkProps } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

interface Props extends LinkProps {
  to: string;
}

export const JnsLink: React.FC<Props> = (props) => {
  return (
    <Link
      as={RouterLink}
      fontWeight="500"
      color="#00A2FF"
      textDecoration="underline"
      _visited={{ color: "gray" }}
      {...props}>
    </Link>
  )
}