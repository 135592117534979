import { Box, BoxProps, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useRippedPaper } from "../hooks/useRippedPaper";

export const RippedPaper: React.FC<BoxProps> = (props: BoxProps) => {
  const { children, ...other } = props;

  const paper = useRippedPaper();

  const textRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!textRef.current || !imgRef.current)
      return;

    const callback: ResizeObserverCallback = () => {
      const width = textRef.current!.getBoundingClientRect().width;

      imgRef.current!.style.width = `${width}px`;
      imgRef.current!.style.top = `-${width / paper.magicNumber}px`;
    }

    const resizeObserver = new ResizeObserver(callback);
    resizeObserver.observe(textRef.current)

    return () => resizeObserver.disconnect();
  }, [textRef.current, imgRef.current])

  return (
    <Box {...other}>
      <Box bg="paper" ref={textRef} position="relative" zIndex="2">
        {children}
       </Box>
      <Box position="relative">
        <Image position="absolute" ref={imgRef} src={paper.src} zIndex="1" />
      </Box>
    </Box>
  );
};
