import { useRef } from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { defaultTheme } from "./theme/theme";
import JNSHeading from "./components/jns-heading";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import HamburgerMenu from "./components/hamburger-menu";
import { useHamburgerMenu } from "./hooks/hamburger-menu";
import { SoundProvider } from "./context/sound-context";
import AppRouts from "./components/routes";
import { VisitedLinkProvider } from "./context/visited-link-context";

const App = () => {

  const { isOpen, toggleOpen, closeMenu } = useHamburgerMenu();
  const headerRef = useRef<HTMLHeadingElement>(null);

  return (
    <ChakraProvider theme={defaultTheme}>
      <BrowserRouter>
        <Box className="App" minHeight="100vh">
          <SoundProvider>
            <VisitedLinkProvider>
              <JNSHeading ref={headerRef} toggleHamburgerMenu={toggleOpen} />
              <HamburgerMenu
                headerRef={headerRef}
                isOpen={isOpen}
                closeHamburgerMenu={closeMenu}
              />
              <AppRouts />
            </VisitedLinkProvider>
          </SoundProvider>
        </Box>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
