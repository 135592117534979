import {
  Modal,
  ModalBody,
  ModalContent,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface JNSModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  backgroundImage?: string;
}

const JNSModal: React.FC<JNSModalProps> = ({
  isOpen,
  onClose,
  children,
  backgroundImage,
}) => {
  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalContent backgroundImage={backgroundImage}>
        <ModalBody display="flex" flexDirection="column" padding="0">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JNSModal;
