import React from "react";
import BasicPage from "./basic-page";
import usePreLoadImage from "../hooks/preload-image";


export const Main9: React.FC = () => {
  usePreLoadImage([
    "/images/main/collage-09-min.png",
    "/images/main/collage-10-min.png"
  ]);
  return (
    <BasicPage
      backgroundImageUri="/images/main/5.jpeg"
      soundUri="/sounds/sound_05.mp3"
      linkToPrevPage="/8"
      linkToNextPage="/10"
    />
  );
};

