import { Box, Container, Stack } from "@chakra-ui/react";
import { ShareWithYourFriends } from "../social/share-with-your-friends";
import { PrimaryButton } from "../buttons/primary-button";
import { useTranslation } from "react-i18next";

interface QuizProps {
  onClose: () => void;
  header: React.ReactNode;
  sections: React.ReactNode;
  socialTitle: string;
}

const Quiz: React.FC<QuizProps> = ({ onClose, header, sections, socialTitle }) => {

  const { t } = useTranslation();

  return (
    <Box
      backgroundImage="/images/background-paper.jpg"
      backgroundSize="contain">
      <Container maxW="72ch" marginTop="6.5rem">
        {header}
      </Container>
      <Container maxW="60ch" marginTop="4rem" marginBottom="6rem">
        <Stack gap="6.25rem">
          {sections}
        </Stack>
        <Stack marginTop="4rem" gap="6.25rem">
          <ShareWithYourFriends title={socialTitle} />
          <PrimaryButton onClick={onClose}>{t("generic.back")}</PrimaryButton>
        </Stack>
      </Container >
    </Box>

  );
};

export default Quiz;
