import { Button, ButtonProps } from "@chakra-ui/react";
import { useState } from "react";

interface Props extends ButtonProps {
  index: number;
}

export const CheckTag = ({ index, children, ...chakra }: Props) => {

  const [isSelected, setIsSelected] = useState(false);

  const variants: Partial<ButtonProps>[] = [{
    fontFamily: "Caveat Brush",
    fontSize: "18px"
  }, {
    fontFamily: "Lora",
  }, {
    fontFamily: "DM Sans",
    textTransform: "uppercase"
  }]

  const rest = index % variants.length;
  const variant = variants[rest];

  const bg = isSelected ? "rgba(255, 255, 255, 0.40)" : "none";
  const border = isSelected ? "2px solid white" : "2px solid transparent";

  return (
    <Button
      bg={bg}
      color="white"
      border={border}
      p="24px"
      _hover={{
        border: "2px solid white"
      }}
      onClick={() => setIsSelected(prev => !prev)}
      {...variant}
      {...chakra}>
      {children}
    </Button>
  )
}