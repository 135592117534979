import { Box } from "@chakra-ui/react"

export const EmbeddedVideo = () => {
  return (
    <Box width="100%" paddingTop="56.25%" position="relative" overflow="hidden">
      <iframe
        style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, width: "100%", height: "100%" }}
        src="https://www.youtube.com/embed/DQzasHMCuJw?si=GiJ--hVo2eKck2SF"
        title="The Story of John Nurminen Foundation"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen></iframe>
    </Box>
  )
}