import React from "react";
import ArticleContainer from "../components/articles/article-container";

export interface MythicalMapsProps {
  onClose: () => void;
}

export const MythicalMaps: React.FC<MythicalMapsProps> = ({ onClose }) => {
  const article = {
    title: "mythicalMaps.title",
    headerParagraphs: ["mythicalMaps.oldMaps"],
    footerParagraphs: ["mythicalMaps.perkele", "mythicalMaps.pictures"],
    areas: [
      {
        useScrollAnimation: true,
        items: [{
          background: "/images/mythicalMaps/01MT_Myytteja-01-Background.jpg",
          title: "mythicalMaps.nordicRegion",
          text: "mythicalMaps.beganToAppear",
          imageAfter: "/images/mythicalMaps/01MT_Myytteja-01-img1.jpg",
        }, {
          text: "mythicalMaps.mapOfNorth"
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-01-img2-min.png",
          text: "mythicalMaps.mapByGermanus"
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-01-img3-min.png",
          text: "mythicalMaps.finlandForExample"
        },
        {
          background: "/images/mythicalMaps/01MT_Myytteja-02-Background.jpg",
          title: "mythicalMaps.theGreatNorth",
          text: "mythicalMaps.cartaMarina",
        },
        {
          text: "mythicalMaps.laidFoundations",
          image: "/images/mythicalMaps/01MT_Myytteja-02-img1-min.png",
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-02-img2-min.png",
          text: "mythicalMaps.scandinaviaReimagined",
        },
        {
          text: "mythicalMaps.starkater",
          image: "/images/mythicalMaps/01MT_Myytteja-02-img3-min.png"
        },
        {
          background: "/images/mythicalMaps/01MT_Myytteja-03-Background.jpg",
          title: "mythicalMaps.seaMonsters",
          text: "mythicalMaps.accurateMapsVital",
          imageAfter: "/images/mythicalMaps/01MT_Myytteja-03-img1.jpg"
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-03-img2-min.png",
          text: "mythicalMaps.sailingGuide"
        },
        {
          text: "mythicalMaps.spiritOfTheTimes",
          image: "/images/mythicalMaps/01MT_Myytteja-03-img3-min.png"
        },
        {
          background: "/images/mythicalMaps/01MT_Myytteja-04-Background.jpg",
          title: "mythicalMaps.strangeDirection",
          text: "mythicalMaps.abrahamOrtelius",
          imageAfter: "/images/mythicalMaps/01MT_Myytteja-04-img1.jpg"
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-04-img2-min.png",
          text: "mythicalMaps.distantNorth"
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-04-img3-min.png",
          text: "mythicalMaps.inThe1500s"
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-04-img4-min.png",
          text: "mythicalMaps.atlantic"
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-04-img5-min.png",
          text: "mythicalMaps.cartouche"
        },
        {
          background: "/images/mythicalMaps/01MT_Myytteja-05-Background.jpg",
          title: "mythicalMaps.undergroundTunnel",
          text: "mythicalMaps.mapOfNorthFrom1665",
          imageAfter: "/images/mythicalMaps/01MT_Myytteja-05-img1.jpg",
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-05-img2-min.png",
          text: "mythicalMaps.mapWasDrawnBy"
        },
        {
          background: "/images/mythicalMaps/01MT_Myytteja-06-Background.jpg",
          title: "mythicalMaps.lostGulfOfBothnia",
          text: "mythicalMaps.dutchCharts",
          imageAfter: "/images/mythicalMaps/01MT_Myytteja-06-img1.jpg",
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-06-img2-min.png",
          text: "mythicalMaps.ghostSea"
        },
        {
          background: "/images/mythicalMaps/01MT_Myytteja-07-Background.jpg",
          title: "mythicalMaps.cursedSkerries",
          text: "mythicalMaps.goldenAge",
          imageAfter: "/images/mythicalMaps/01MT_Myytteja-07-img1.jpg",
        },
        {
          image: "/images/mythicalMaps/01MT_Myytteja-07-img2-min.png",
          text: "mythicalMaps.magnificientAtlasMap"
        }]
      }]
  }

  return (
    <ArticleContainer
      onClose={onClose}
      {...article}
    />
  );
};

