import { Image, Stack } from "@chakra-ui/react";
import { Heading5 } from "../typography/Heading5";
import { useTranslation } from "react-i18next";
import { JnsLink } from "../typography/JnsLink";

interface Props {
  title: string;
  image: string;
  linkTo: string;
  isActive: boolean;
  isInactive: boolean;
}

export const TabArticleTab: React.FC<Props> = ({
  linkTo,
  title,
  image,
  isActive,
  isInactive
}) => {

  const { t } = useTranslation();

  return (
    <Stack alignItems="center" filter={isInactive ? "grayscale(100%)" : ""}>
      <Image src={image}></Image>
      <Heading5 textAlign="center" color={isActive ? "gold" : undefined}>{t(title)}</Heading5>
      <JnsLink to={linkTo}>
        {t("generic.read")}
      </JnsLink>
    </Stack>
  );
};

