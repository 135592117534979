import { useTranslation } from "react-i18next";
import { Box, Text } from "@chakra-ui/react";

interface Props {
  quote: string;
  attribution: string;
}

export const ArticleAreaSectionQuote = (props: Props) => {

  const { t } = useTranslation();

  const { quote, attribution } = props;

  return (
    <Box
      background="brand"
      color="white"
      p={{ base: "2rem", lg: "3rem" }}
    >
      <Text fontFamily="Lora" fontSize="24px" lineHeight="34px">{t(quote)}</Text>
      <Text fontFamily="Lora" fontSize="16px" marginTop="24px">– {t(attribution)}</Text>
    </Box>
  )
}