import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface PageTransitionProps extends BoxProps {
  children: ReactNode;
  backgroundImageUri: string;
}

const PageTransition: React.FC<PageTransitionProps> = ({
  children,
  backgroundImageUri,
  ...other
}) => {

  return (
    <Box
      display="flex"
      flex="1"
      height="100%"
      width="100%"
      backgroundImage={backgroundImageUri}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      position="relative"
      {...other}
    >
      {children}
    </Box>
  );
};

export default PageTransition;
