import { Box } from "@chakra-ui/layout";
import { Container, Link as ChakraLink, Stack, VStack } from "@chakra-ui/react";
import { ShareWithYourFriends } from "../social/share-with-your-friends";
import { PrimaryButton } from "../buttons/primary-button";
import { TabArticleTab } from "./tab-article-tab";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { SecondaryButton } from "../buttons/secondary-button";
import { useTranslation } from "react-i18next";
import { colors } from "../../theme/theme";
import { Quote } from "../typography/Quote";
import { BodyText } from "../typography/BodyText";

interface Props {
  onClose: () => void
  header: React.ReactNode;
  socialTitle: string;
  baseRoute: string;
  tabs: Tab[]
  quote?: Quote;
}

interface Quote {
  quote: string;
  attribution: string;
}

interface Tab {
  title: string;
  image: string;
  content: React.ReactNode;
}

export const TabArticle: React.FC<Props> = ({
  onClose,
  header,
  socialTitle,
  tabs,
  baseRoute,
  quote
}) => {

  const { t } = useTranslation();
  const { tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (tab !== undefined) {

      const offset = articleContainer.current?.offsetTop;
      if (!offset)
        return;

      outerContainer.current?.scrollTo({
        behavior: "smooth",
        top: offset - 48
      });
    }
  }, [tab])

  const activeTabIndex = parseInt(tab ?? "");
  const anyTabActive = !isNaN(activeTabIndex);

  const articleContainer = useRef<HTMLDivElement>(null);
  const outerContainer = useRef<HTMLDivElement>(null);

  const toUrl = (tabIndex: number) => {
    return `${baseRoute}${tabIndex}`;
  }

  return (
    <Box
      height="100vh"
      overflow="auto"
      ref={outerContainer}
      backgroundImage="/images/background-paper.jpg"
      paddingTop="6.5rem"
      paddingBottom="2rem"
      backgroundSize="contain">
      <Container maxW="85ch">
        <VStack gap="4rem">
          {header}
          <Stack direction={{
            base: "column",
            md: "row"
          }}>
            {tabs.map((x, i) => (
              <Box key={x.title} cursor="pointer" onClick={() => navigate(toUrl(i))}>
                <TabArticleTab
                  key={x.title}
                  image={x.image}
                  isActive={i == activeTabIndex}
                  isInactive={anyTabActive && i !== activeTabIndex}
                  linkTo={toUrl(i)}
                  title={x.title} />
              </Box>
            ))}
          </Stack>
          <Box ref={articleContainer}>
            {anyTabActive && tabs[activeTabIndex].content}
          </Box>
          {anyTabActive &&
            <VStack width="100%" alignItems="stretch" gap="16px" marginTop="2.5rem">
              {tabs.map((x, i) => (
                i !== activeTabIndex &&
                <ChakraLink key={x.title} as={Link} to={toUrl(i)}>
                  <SecondaryButton key={x.title} buttonColor={colors.brand} width="100%">
                    {t(x.title)}
                  </SecondaryButton>
                </ChakraLink>
              ))}
              <PrimaryButton onClick={onClose}>{t("generic.back")}</PrimaryButton>
            </VStack>
          }
          {quote &&
            <VStack gap="1.5rem">
              <Quote>{t(quote.quote)}</Quote>
              <BodyText>{t(quote.attribution)}</BodyText>
            </VStack>
          }
          <ShareWithYourFriends title={socialTitle} />
        </VStack>
      </Container >
    </Box>
  );
};

