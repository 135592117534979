import { Box, FlexProps } from "@chakra-ui/react"


export const NavButtonContainer = (props: FlexProps) => {
  return (
    <Box
      pos="fixed"
      zIndex={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      bottom="24px"
      gap="32px"
      {...props} />
  )
}