import { useTranslation } from "react-i18next";
import { Heading3 } from "../typography/Heading3";

interface QuizQuestionProps {
  question: string;
}

const QuizQuestion: React.FC<QuizQuestionProps> = ({ question }) => {
  const { t } = useTranslation();

  return (
    <Heading3 marginBottom="1.5rem">
      {t(question)}
    </Heading3>
  );
};

export default QuizQuestion;
