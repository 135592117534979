import React from "react";
import { Box, Container, HStack, Icon, VStack, Image } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Heading1 } from "../components/typography/Heading1";
import { BodyText } from "../components/typography/BodyText";
import { NavLeft } from "../components/buttons/nav-left";
import { NavRight } from "../components/buttons/nav-right";
import usePreLoadImage from "../hooks/preload-image";
import { NavButtonContainer } from "../components/buttons/nav-button-container";
import { NavButton } from "../components/buttons/nav-button";
import { Copyright } from "../components/copyright";

export const Intro: React.FC = () => {
  const { t } = useTranslation();

  usePreLoadImage([
    "/images/main/1.jpg",
    "/images/rippedpaper-01.png",
    "/images/rippedpaper-02.png",
    "/images/rippedpaper-03.png",
    "/images/rippedpaper-04.png",
  ]);

  return (
    <Box
      height="calc(100vh - 40px)"
      backgroundImage="/images/common/background-darkblue.jpg"
      position="relative"
      backgroundSize="cover"
    >
      <Container
        id="intro-container"
        display="flex"
        height="100%"
        overflowY="auto"
        pt="100px"
        maxW="72ch"
        pb="6rem"
        flexDir="column"
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start" gap="24px">
          <Image
            alignSelf="center"
            src={t("intro.image")}
            height="100px"
          ></Image>
          <Heading1 color="white">{t("intro.title")}</Heading1>
          <BodyText color="white">{t("intro.subtitle")}</BodyText>
          <BodyText color="white">{t("intro.welcome")}</BodyText>
          <BodyText color="white">{t("intro.diveInto")}</BodyText>
          <BodyText color="white">{t("intro.navigation")}</BodyText>
          <HStack gap="1rem">
            <NavLeft aria-label="Example of a left button" />
            <NavRight aria-label="Example of a right button" />
          </HStack>
          <Box>
            <BodyText color="white">{t("intro.titles")}</BodyText>
            <BodyText color="white">{t("intro.back")}</BodyText>
          </Box>
          <Copyright />
        </VStack>
      </Container>
      <NavButtonContainer>
        <Link to="1">
          <NavButton pos="relative" primary>
            {t("intro.start")}
            <Icon marginLeft="12px" fontSize="1.5rem">
              <FiArrowRight />
            </Icon>
          </NavButton>
        </Link>
      </NavButtonContainer>
    </Box>
  );
};
