import React from "react";
import BasicPage from "./basic-page";
import usePreLoadImage from "../hooks/preload-image";


export const Main7: React.FC = () => {
  usePreLoadImage([
    "/images/main/collage-07-min.png",
    "/images/main/collage-08-min.png"
  ]);
  return (
    <BasicPage
      backgroundImageUri="/images/main/4.jpeg"
      soundUri="/sounds/sound_04-2.mp3"
      linkToPrevPage="/6"
      linkToNextPage="/8"
    />
  );
};

