import React from "react";
import CollagePage from "./collage-page";
import { ThreateningBalticQuiz } from "./threatening-baltic-quiz";
import { BrutalFatesArticle } from "./brutal-fates-article";
import usePreLoadImage from "../hooks/preload-image";

export const Main4: React.FC = () => {
  usePreLoadImage([
    "/images/main/3.jpeg"
  ]);

  return (
    <CollagePage
      backgroundImageUri="/images/main/2.jpeg"
      soundUri="/sounds/sound_02.mp3"
      linkToPrevPage="/3"
      linkToThisPage="/4"
      linkToNextPage="/5"
      captionLeft="mainCaptions.brutalFatesInBaltic"
      captionRight="mainCaptions.threateningBaltic"
      imageLeftUri="/images/main/collage-03-min.png"
      imageRightUri="/images/main/collage-04-min.png"
      leftModalContent={(onClose) => (<BrutalFatesArticle onClose={onClose} />)}
      rightModalContent={(onClose) => <ThreateningBalticQuiz onClose={onClose} />}
    />
  );
};

