import { Text, TextProps } from "@chakra-ui/react";


export const LabelPrimaryEmphasis: React.FC<TextProps> = (props) => {
  return <Text
    color="brand"
    fontSize="1rem"
    fontWeight="700"
    lineHeight="24px"
    fontFamily="DM Sans"
    {...props}></Text>
}