import React from "react";
import CollagePage from "./collage-page";
import { GoalsCourage } from "./goals-courage";
import { MarineArt } from "./marine-art";
import usePreLoadImage from "../hooks/preload-image";

export const Main6: React.FC = () => {
  usePreLoadImage([
    "/images/main/4.jpeg"
  ]);

  return (
    <CollagePage
      soundUri="/sounds/sound_03.mp3"
      backgroundImageUri="/images/main/3.jpeg"
      linkToPrevPage="/5"
      linkToThisPage="/6"
      linkToNextPage="/7"
      captionLeft="mainCaptions.marineArt"
      captionRight="mainCaptions.goalsCourage"
      imageLeftUri="/images/main/collage-05-min.png"
      imageRightUri="/images/main/collage-06-min.png"
      leftModalContent={(onClose) => <MarineArt onClose={onClose} />}
      rightModalContent={(onClose) => <GoalsCourage onClose={onClose} />}
    />
  );
};

