import { Box, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiCheck, FiX } from "react-icons/fi";

interface QuizAnswerButtonProps {
  answer: string;
  onClick: () => void;
  isCorrect: boolean;
  isWrong: boolean;
}

export const QuizAnswerButton: React.FC<QuizAnswerButtonProps> = ({
  answer,
  onClick,
  isCorrect,
  isWrong,
}) => {

  const { t } = useTranslation();

  let background = "brand";
  let border = "none"
  let color = "white";

  let circleBackground = "white";
  let circleShadow = "none";

  let iconComponent: React.ReactNode = null;

  if (isCorrect) {
    background = "#F3FFEC";
    border = "1px solid #9AFF63";
    iconComponent = (<Icon color="#9AFF63" marginLeft="auto" fontSize="1.6rem"><FiCheck /></Icon>);
  }
  else if (isWrong) {
    background = "#FFECEB";
    border = "1px solid #FF655C";
    iconComponent = (<Icon color="#FF655C" marginLeft="auto" fontSize="1.6rem"><FiX /></Icon>);
  }

  if (isCorrect || isWrong){
    color = "brand";
    circleBackground = "brand";
    circleShadow = "inset 0 0 0 1px #22275E, inset 0 0 0 3px white"
  }


  return (
    <Box
      as="button"
      width="100%"
      padding="1rem"
      borderRadius="0.5rem"
      background={background}
      border={border}
      color={color}
      marginBottom="0.75rem"
      display="flex"
      justifyContent="flex-start"
      onClick={onClick}
    >
      <Box
        borderRadius="50%"
        flex="0 0 1rem"
        marginTop="4px"
        height="1rem"
        boxShadow={circleShadow}
        background={circleBackground}
        marginRight="1rem"></Box>
      <Text textAlign="left" fontWeight="600">{t(answer)}</Text>
      {iconComponent}
    </Box >
  );
};
