import { QuizHeader } from "../components/quizzes/quiz-header"
import { TabArticle } from "../components/tab-article/tab-article"
import { TabArticleTabContent } from "../components/tab-article/tab-article-tab-content"

interface Props {
  onClose: () => void
}

export const MarineArt: React.FC<Props> = ({ onClose }) => {

  const eveningInRauma = <TabArticleTabContent
    title="marineArt.eveningInRauma.title"
    intro="marineArt.eveningInRauma.intro"
    image="/images/marineArt/MK04_Rohkeus-img4.jpg"
    imageText="marineArt.eveningInRauma.imageText"
    paragraphs={["marineArt.eveningInRauma.today", "marineArt.eveningInRauma.schooner"]}
    credits="marineArt.eveningInRauma.photo"
  />

  const german = <TabArticleTabContent
    title="marineArt.german.title"
    intro="marineArt.german.intro"
    image="/images/marineArt/MK04_Rohkeus-img5.jpg"
    imageText="marineArt.german.imageText"
    paragraphs={["marineArt.german.lighthouse", "marineArt.german.painting", "marineArt.german.builtIn"]}
    credits="marineArt.german.photo"
/>
  const visualArtist = <TabArticleTabContent
    title="marineArt.visualArtist.title"
    intro="marineArt.visualArtist.intro"
    image="/images/marineArt/MK04_Rohkeus-img6.jpg"
    imageText="marineArt.visualArtist.imageText"
    paragraphs={["marineArt.visualArtist.portOfRauma", "marineArt.visualArtist.iissalo"]}
    credits="marineArt.visualArtist.photo"
/>

  return (
    <TabArticle
      onClose={onClose}
      baseRoute="/6/left/"
      socialTitle=""
      header={<QuizHeader
        onClickBack={onClose}
        title="marineArt.title"
        paragraphs={[
          "marineArt.aimsToSave",
          "marineArt.artCollection",
          "marineArt.hereAreSome"
        ]}></QuizHeader>}
      tabs={[
        {
          title: "marineArt.eveningInRauma.title",
          image: "/images/marineArt/MK04_Rohkeus-img1-min.png",
          content: eveningInRauma
        },
        {
          title: "marineArt.german.title",
          image: "/images/marineArt/MK04_Rohkeus-img2-min.png",
          content: german
        },
        {
          title: "marineArt.visualArtist.title",
          image: "/images/marineArt/MK04_Rohkeus-img3-min.png",
          content: visualArtist
        }
      ]}
    />
  )
}