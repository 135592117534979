import {
  Flex,
  IconButton,
  Heading,
  Box,
  HeadingProps,
  forwardRef,
} from "@chakra-ui/react";
import { FiMenu, FiVolume2, FiVolumeX } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useSoundContext } from "../context/sound-context";

interface JNSHeadingProps extends HeadingProps {
  toggleHamburgerMenu: () => void;
}

const JNSHeading: React.ForwardRefRenderFunction<
  HTMLHeadingElement,
  JNSHeadingProps
> = ({ toggleHamburgerMenu }, ref) => {
  const [selectedLanguage, setSelectedLanguage] = useState("FI");

  const { t, i18n } = useTranslation();
  const languages = [t("languageEN"), t("languageSWE"), t("languageFI")];

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang.toLowerCase());
    setSelectedLanguage(lang);
  };

  const { soundOn, toggleSound } = useSoundContext();

  const languageButtons = languages.map((item, index) => (
    <Box
      as="button"
      key={item}
      fontSize="16px"
      px="1rem"
      borderLeft={index > 0 ? "2px solid white" : ""}
      transition="color .1s"
      color={item === selectedLanguage ? "blue" : "white"}
      _hover={{
        color: "lightBlue",
      }}
      fontWeight="500"
      fontFamily="inter"
      onClick={() => handleLanguageChange(item)}
    >
      {item}
    </Box>
  ));

  return (
    <Heading
      display="flex"
      position="sticky"
      top="0"
      zIndex={20000}
      width="100%"
      justifyContent="flex-end"
      backgroundColor="darkBlue"
      ref={ref}
      padding="0px 48px"
    >
      <Flex justifyContent="flex-end" alignItems="center">
        {languageButtons}
        <IconButton
          ml="2.75rem"
          mr="2rem"
          color="white"
          aria-label="Sound"
          fontSize="1.75rem"
          variant="ghost"
          transition="color .1s"
          _hover={{
            color: "lightBlue",
          }}
          _active={{}}
          icon={soundOn ? <FiVolume2 /> : <FiVolumeX />}
          onClick={toggleSound}
        />
        <IconButton
          color="white"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            toggleHamburgerMenu();
          }}
          transition="color .1s"
          _hover={{
            color: "lightBlue",
          }}
          _active={{}}
          fontSize="1.75rem"
          aria-label="Menu"
          variant="ghost"
          icon={<FiMenu />}
        />
      </Flex>
    </Heading>
  );
};

export default forwardRef(JNSHeading);
