import React from "react";
import CollagePage from "./collage-page";
import { Mythbuster } from "./mythbuster";
import { MythicalMaps } from "./mythical-maps";
import usePreLoadImage from "../hooks/preload-image";

export const Main2: React.FC = () => {
  usePreLoadImage([
    "/images/main/2.jpeg"
  ]);


  return (
    <CollagePage
      backgroundImageUri="/images/main/1.jpg"
      soundUri="/sounds/sound_01.mp3"
      linkToPrevPage="/1"
      linkToThisPage="/2"
      linkToNextPage="/3"
      captionLeft="mainCaptions.mythicalMapsOfTheNorth"
      captionRight="mainCaptions.areYouMythBuster"
      imageLeftUri="/images/main/collage-01-min.png"
      imageRightUri="/images/main/collage-02-min.png"
      leftModalContent={(onClose) => (<MythicalMaps onClose={onClose} />)}
      rightModalContent={(onClose) => <Mythbuster onClose={onClose} />}
    />
  );
};

