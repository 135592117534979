import { Flex, Stack, Link as ChakraLink, Box } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Heading3 } from "./typography/Heading3";

interface HamburgerMenuProps {
  headerRef: React.RefObject<HTMLHeadingElement>;
  isOpen: boolean;
  closeHamburgerMenu: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  headerRef,
  isOpen,
  closeHamburgerMenu,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    closeHamburgerMenu();
  }, [location.pathname])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeHamburgerMenu();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, closeHamburgerMenu]);

  const links = [
    {
      route: "/",
      name: "mainCaptions.toTheStartInfo",
    },

    {
      route: "/2/left",
      name: "mainCaptions.mythicalMapsOfTheNorth",
    }, {
      route: "/2/right",
      name: "mainCaptions.areYouMythBuster",
    },

    {
      route: "/4/left",
      name: "mainCaptions.brutalFatesInBaltic",
    }, {
      route: "/4/right",
      name: "mainCaptions.threateningBaltic",
    },

    {
      route: "/6/left",
      name: "mainCaptions.marineArt",
    }, {
      route: "/6/right",
      name: "mainCaptions.goalsCourage",
    },

    {
      route: "/8/left",
      name: "mainCaptions.foundation",
    }, {
      route: "/8/right",
      name: "mainCaptions.positiveMessages",
    },

    {
      route: "/10/left",
      name: "mainCaptions.balticMemories",
    }, {
      route: "/10/right",
      name: "mainCaptions.wwyd",
    },
  ]

  return (
    <>
      {isOpen && <Box position="fixed" width="100vh" height="100vh" zIndex="19999" bg="transparent" onClick={closeHamburgerMenu} />}
      <Flex
        position="fixed"
        transform={isOpen ? "translateX(0)" : "translateX(320px)"}
        transition="transform 0.2s"
        top={headerRef.current?.clientHeight + "px"}
        right={0}
        zIndex={20000}
        padding="1rem"
        w="320px"
        h="100%"
        paddingTop="100px"
        justifyContent="center"
        ref={menuRef}
        bg="rgba(19, 22, 47, 0.90)"
      >
        <Stack>
          <Heading3 color="white">{t("intro.title")}</Heading3>
          {links.map(x =>
            <ChakraLink
              as={Link}
              key={x.route}
              to={x.route}
              color="white"
            >
              {t(x.name)}
            </ChakraLink>
          )}
          <ChakraLink
            as={Link}
            mt="1rem"
            to="/credits"
            color="white"
          >
            {t("mainCaptions.credits")}
          </ChakraLink>
        </Stack>
      </Flex>
    </>
  );
};

export default HamburgerMenu;
