import React from "react";
import ArticleContainer from "../components/articles/article-container";

interface Props {
  onClose: () => void;
}

export const BrutalFatesArticle: React.FC<Props> = ({ onClose }) => {
  const article = {
    title: "brutalFates.title",
    headerParagraphs: [
      "brutalFates.naturalForces",
      "brutalFates.situations",
      "brutalFates.nurminen"],
    footerTitle: "brutalFates.specsTitle",
    footerParagraphs: [
      "brutalFates.specs",
      "brutalFates.photos",
    ],
    areas: [
      {
        useScrollAnimation: true,
        items: [{
          background: "/images/brutalFates/02MT_Uhka-01-Background.jpg",
          title: "brutalFates.steamshipLeena",
          text: "brutalFates.cargoSteamer",
        }, {
          text: "brutalFates.builtInEngland",
        },
        {
          image: "/images/brutalFates/02MT_Uhka-01-img1-min.png",
          text: "brutalFates.tilted"
        },
        {
          background: "/images/brutalFates/02MT_Uhka-02-Background.jpg",
          image: "/images/brutalFates/02MT_Uhka-02-img1-min.png",
          text: "brutalFates.windInNorway",
        }, {
          image: "/images/brutalFates/02MT_Uhka-02-img2-min.png",
          text: "brutalFates.war",
        }, {
          background: "/images/brutalFates/02MT_Uhka-03-Background.jpg",
          text: "brutalFates.faith",
        }, {
          image: "/images/brutalFates/02MT_Uhka-03-img1-min.png",
          text: "brutalFates.toCopenhagen",
        },
        {
          image: "/images/brutalFates/02MT_Uhka-03-img2-min.png",
          text: "brutalFates.severeStorm",
        },
        {
          background: "/images/brutalFates/02MT_Uhka-04-Background.jpg",
          image: "/images/brutalFates/02MT_Uhka-04-img1-min.png",
          text: "brutalFates.afterAccident",
        },
        {
          image: "/images/brutalFates/02MT_Uhka-04-img2.jpg",
          text: "brutalFates.names",
        },
        {
          image: "/images/brutalFates/02MT_Uhka-04-img3.jpg",
          text: "brutalFates.news",
        }]
      }
    ]
  }

  return (
    <ArticleContainer
      onClose={onClose}
      {...article}
    />
  );
};

