import { Text, TextProps } from "@chakra-ui/react";


export const Heading3: React.FC<TextProps> = (props) => {
  return <Text
    color="brand"
    fontSize="2rem"
    fontWeight="500"
    lineHeight="36px"
    fontFamily="Lora"
    {...props}></Text>
}