import { Image, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RippedPaper } from "../ripped-paper";
import { Heading3 } from "../typography/Heading3";
import { BodyText } from "../typography/BodyText";
import { LabelPrimaryEmphasis } from "../typography/LabelPrimaryEmphasis";

interface Props {
  title: string;
  intro: string;
  image: string;
  imageText?: string;
  paragraphs: string[];
  credits: string;
}


export const TabArticleTabContent: React.FC<Props> = ({
  title,
  image,
  intro,
  imageText,
  paragraphs,
  credits
}) => {

  const { t } = useTranslation();

  return (
    <RippedPaper>
      <VStack p="32px" gap="24px">
        <Heading3>{t(title)}</Heading3>
        <BodyText dangerouslySetInnerHTML={{ __html: t(intro) }}></BodyText>
        <Image src={image}></Image>
        {imageText && <LabelPrimaryEmphasis>{t(imageText)}</LabelPrimaryEmphasis>}
        {paragraphs.map(x => <BodyText key={x} dangerouslySetInnerHTML={{ __html: t(x) }} ></BodyText>)}
        {credits && <LabelPrimaryEmphasis>{t(credits)}</LabelPrimaryEmphasis>}
      </VStack>

    </RippedPaper>
  );
};

